<template>
  <div class="sider-container">
    <el-menu
      :unique-opened="true"
      :default-active="defaultActive"
      @select="handleSelect"
    >
      <template v-for="menuItem in menuList">
        <el-submenu
          v-if="menuItem.children && menuItem.children.length"
          :key="menuItem.key"
          :index="menuItem.key"
        >
          <template slot="title">
            <i :class="menuItem.icon"></i><span>{{ menuItem.title }}</span>
          </template>
          <template v-for="menuSubItem in menuItem.children">
            <el-menu-item
              :key="menuSubItem.key"
              :index="menuSubItem.key"
            >
              {{ menuSubItem.title }}
            </el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item
          v-else
          :key="(menuItem.key)"
          :index="menuItem.key"
        >
          <i :class="menuItem.icon"></i>{{ menuItem.title }}
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import menuData from './data'
export default {
  name: 'Sider',
  data () {
    return {
      menuList: menuData
    }
  },
  computed: {
    defaultActive () {
      let route = this.$route
      if (route.name) return route.meta.menuKey ? route.meta.menuKey : route.name.replace('/', '')
      return ''
    },
  },
  methods: {
    handleSelect (index, indexPath) {
      let menu = this.menuList.find(item => item.key === indexPath[0])
      if (indexPath.length > 1) {
        indexPath.slice(1).forEach(path => {
          menu = menu.children.find(item => item.key === path)
        })
      }
      if (menu.link) {
        this.$router.push(menu.link)
      } else if (this.$route.name !== menu.key) {
        this.$router.push({
          name: menu.key
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sider-container {
  width: $width-side-bar;
  height: 100vh;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  .el-menu {
    border-right: initial;
  }
}
</style>
