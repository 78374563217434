/* layout */
import BasicLayout from '@/layout/basic-layout/BasicLayout'

/* Demo */
const Demo = () => import('@/pages/demo/Demo')

// 数据导出
const DataExport = () => import('@/pages/data-export/DataExport')

// 数据导入
const DataImport = () => import('@/pages/data-import/DataImport')

// 视频数据分析
const DataAnalysis = () => import('@/pages/data-analysis/index')
const DataAnalysisDetail = () => import('@/pages/data-analysis/detail')
// 登录
const Login = () => import('@/pages/login/Login')

// 用户管理
const UserManage = () => import('@/pages/user-manage/UserManage')

// 用户管理
const Analysis = () => import('@/pages/analysis/DataAnalysis')

/* 404 */
const NotFound = () => import('@/pages/not-found/NotFound')

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '',
    component: BasicLayout,
    children: [
      {
        path: '/demo',
        name: 'demo',
        component: Demo
      },
      {
        path: '/data_export',
        name: 'DataExport',
        component: DataExport
      },
      {
        path: '/data_import',
        name: 'DataImport',
        component: DataImport
      },
      {
        path: '/data_analysis',
        name: 'DataAnalysis',
        component: DataAnalysis
      },
      {
        path: '/data_analysis/:id',
        name: 'DataAnalysisDetail',
        component: DataAnalysisDetail,
        // 设置详情侧边栏高亮
        meta: {
          menuKey: 'DataAnalysis'
        }
      },
      {
        path: '/user_manage',
        name: 'UserManage',
        component: UserManage
      },
      {
        path: '/analysis',
        name: 'Analysis',
        component: Analysis
      },
    ]
  },
  {
    path: '/404',
    component: NotFound,
  },
  {
    path: '*',
    redirect: '/404',
  }
]

export default routes