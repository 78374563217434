<template>
  <div class="basic-layout-container">
    <div class="header">
      <div class="logo-box">
        <div class="logo"></div>
      </div>
    </div>
    <div class="basic-content-layout">
      <div class="basic-side-box">
        <Sider></Sider>
      </div>
      <div class="content-layout">
        <div class="content-box">
          <div class="basic-content">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sider from './components/sider/Sider'
export default {
  name: 'BasicLayout',
  components: {
    Sider,
  },
  data () {
    return {
    }
  },
}
</script>

<style scoped lang="scss">
.basic-layout-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .basic-content-layout {
    display: flex;
    background: #f0f2f5;
    .basic-side-box {
      position: relative;
    }
    .content-layout {
      width: calc(100vw - #{$width-side-bar});
      .content-box {
        padding: 18px;
        overflow-x: scroll;
        height: 100vh;
        .basic-content {
          padding: 20px;
          margin-bottom: 30px;
          min-width: 1200px;
          background: #ffffff;
        }
      }
    }
  }
}
</style>
