export default [
  // {
  //   key: 'demo',
  //   title: '示例',
  //   icon: 'el-icon-tickets',
  //   authCode: '',
  // },
  // {
  //   key: 'demo01',
  //   title: '示例01',
  //   icon: 'el-icon-tickets',
  //   authCode: '',
  // },
  {
    key: 'Analysis',
    title: '数据看板',
    icon: 'el-icon-s-data',
    authCode: '',
  },
  {
    key: 'DataExport',
    title: '分析数据导出',
    icon: 'el-icon-download',
    authCode: '',
  },
  {
    key: 'DataImport',
    title: '锚点数据导入',
    icon: 'el-icon-upload2',
    authCode: '',
  },
  {
    key: 'DataAnalysis',
    title: '视频数据分析',
    icon: 'el-icon-video-play',
    authCode: '',
  },
  {
    key: 'UserManage',
    title: '用户管理',
    icon: 'el-icon-s-custom',
    authCode: '',
  },
]