import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import Router from 'vue-router'
import Element, { Message } from 'element-ui'
import '@/style/base'
import '@/style/element-variables'

const message = {}
const messageType = ['success', 'error', 'warning', 'info']
messageType.forEach(type => {
  message[type] = (message, duration = 2000) => Message({
    message, type, duration,
    offset: 20
  })
})

Vue.use(Element)

Vue.config.productionTip = false

Vue.prototype.$message = message

// 解决路由跳转报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
